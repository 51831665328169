import { createRouter, createWebHistory } from "vue-router";

// import { DEFAULT_BROWSER_LANGUAGE, FALLBACK_LANGUAGE } from "@/i18n";

import voeuxRoutes from "./voeuxRoutes";
// import webinarRoutes from "./webinarRoutes";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // {
    //   path: "",
    //   redirect: `/${DEFAULT_BROWSER_LANGUAGE ?? FALLBACK_LANGUAGE}/live-sessions`,
    // },
    // webinarRoutes,
    voeuxRoutes,
    {
      path: "/voeux",
      redirect: "/",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const utmParams = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
  const query = { ...to.query };
  let hasChanges = false;

  utmParams.forEach((param) => {
    if (from.query[param] && !to.query[param]) {
      query[param] = from.query[param];
      hasChanges = true;
    }
  });

  if (hasChanges) {
    next({
      ...to,
      query,
      replace: true,
    });
  } else {
    next();
  }
});

export default router;
