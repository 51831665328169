<script lang="ts" setup>
import { useWindowSize } from "@vueuse/core";
import { computed, useTemplateRef } from "vue";

import { speakersData } from "@/speakersData";

import type Rings from "./Rings.vue";
import Sidebar from "./Sidebar.vue";
import Speaker from "./Speaker.vue";

type RingsSvgType = InstanceType<typeof Rings>;
const ringsRef = useTemplateRef<RingsSvgType>("rings");

const windowSize = useWindowSize();

const isDesktopAnimation = computed(() => {
  return windowSize.width.value > 1024;
});

const nbOfSpeakers = Object.values(speakersData).flat().length;

defineExpose({ ringsRef });
</script>

<template>
  <div class="relative inset-0 m-auto h-[100dvh] w-[200vw] lg:w-[100vw]">
    <div class="speaker-rings absolute h-fit w-fit will-change-auto">
      <slot />

      <div class="relative h-[1051px] w-[1119px]">
        <div id="third-ring">
          <img
            src="/assets/images/voeux/ring-3.png"
            class="third-ring pointer-events-none absolute inset-0 m-auto object-cover"
          />
        </div>

        <div id="first-ring">
          <img
            src="/assets/images/voeux/ring-1.png"
            class="first-ring pointer-events-none absolute inset-0 m-auto object-cover"
          />

          <div class="first-ring-rotate absolute inset-0 m-auto">
            <img
              src="/assets/images/voeux/ring-1.png"
              class="first-ring pointer-events-none inset-0 z-0 m-auto object-cover opacity-0"
            />

            <Speaker
              v-for="(speaker, i) in speakersData.thirdRing"
              :key="i"
              class="speaker-anim-first-ring"
              :data="speaker"
              :nbOfSpeakers
            />
          </div>
        </div>

        <div id="second-ring" class="pointer-events-none">
          <img
            src="/assets/images/voeux/ring-2.png"
            class="second-ring pointer-events-none absolute inset-0 m-auto object-cover"
          />

          <div class="second-ring-rotate absolute inset-0 m-auto">
            <img
              src="/assets/images/voeux/ring-2.png"
              class="second-ring pointer-events-none inset-0 z-0 m-auto object-cover opacity-0"
            />

            <Speaker
              v-for="(speaker, i) in speakersData.secondRing"
              :key="i"
              class="speaker-anim-second-ring"
              :data="speaker"
              :nbOfSpeakers
            />
          </div>
        </div>
      </div>
    </div>

    <Sidebar v-if="isDesktopAnimation" />
  </div>
</template>

<style lang="scss" scoped>
.speaker-rings {
  opacity: 0;
  transform: translate(-50%, -50%) scale(0.1);
  top: 50%;
  left: 50%;
}
</style>
