type Callback = (modalName: string, childrenRef: string) => void;

type ModalEventName = "open" | "close";

interface ModalEvent {
  name: ModalEventName;
  callback: Callback;
  once: boolean;
}

class ModalEventEmitter {
  private eventList: ModalEvent[] = [];

  private eventEmitCount = 0;

  private emitLoopLimit = 1;

  on(name: ModalEventName, callback: Callback): void {
    this.eventList.push({ name, callback, once: false });
  }

  once(name: ModalEventName, callback: Callback): void {
    this.eventList.push({ name, callback, once: true });
  }

  off(name: ModalEventName, callback: Callback): boolean {
    const index = this.eventList.findIndex(
      (event) => event.name === name && event.callback === callback,
    );
    if (index > -1) {
      this.eventList.splice(index, 1);
      return true;
    }
    return false;
  }

  protected emit(modalName: string, modalEventName: ModalEventName): void {
    const childrenRef = `contentModal_${modalName}`;

    // Use eventEmitCount to avoid recursive calls.
    if (this.eventEmitCount < this.emitLoopLimit) {
      this.eventEmitCount += 1;

      // Filter subscribed events which match the name of the current event.
      const events = this.eventList.filter((event) => event.name === modalEventName);

      // For each event filtered, make sure to call their respective `off` callback.
      events.filter((event) => event.once).forEach((event) => this.off(event.name, event.callback));

      // Loop though all events and call their respective callbacks.
      for (let i = 0; i < events.length; i += 1) {
        const event = events[i];
        event.callback(modalName, childrenRef);
      }

      this.eventEmitCount -= 1;

      return undefined;
    }

    throw new Error("Infinite loop reached");
  }

  public open(modalName: string): void {
    this.emit(modalName, "open");
  }

  public close(modalName: string): void {
    this.emit(modalName, "close");
  }
}

const modalEventEmitter = new ModalEventEmitter();

export default modalEventEmitter;
