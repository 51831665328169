<script lang="ts" setup>
import { useWindowSize } from "@vueuse/core";
import { useRouteQuery } from "@vueuse/router";
import { computed, nextTick, ref, watch, watchEffect } from "vue";

import { useMatomo } from "@/composables/useMatomo";
import { useQuestionsPanel } from "@/composables/useQuestionsPanel";

import QuestionsCarouselDesktop from "./QuestionsCarouselDesktop.vue";
import QuestionsCarouselMobile from "./QuestionsCarouselMobile.vue";

const { nextPanelQuestions, previousPanelQuestions, closePanelQuestions } = useQuestionsPanel();
const currentQuestionId = useRouteQuery<string>("question");

const { trackQuestionsPageView } = useMatomo();
const windowSize = useWindowSize();
const questionsPanelData = [
  {
    id: 1,
    video: "https://www.youtube.com/embed/sbjcUEJUWbw",
    question: "What do you wish you had known 25 years ago?",
  },
  {
    id: 2,
    video: "https://www.youtube.com/embed/KPm7JnAoETs",
    question: "What do you wish for the digital world in 2025?",
  },
  {
    id: 3,
    video: "https://www.youtube.com/embed/h5j8DWk6-6U",
    question: "What is one wish of yours that has come true in the past 25 years?",
  },
  {
    id: 4,
    video: "https://www.youtube.com/embed/-wOc1UkDxME",
    question: "What impact do you wish to have on your ecosystem in 2025?",
  },
  {
    id: 5,
    video: "https://www.youtube.com/embed/itP0XMk5jBM",
    question: "What would you wish for Numberly in 2025?",
  },
];

const currentQuestion = computed(() => {
  const normalizeModulo = (n: number, mod: number) => ((n % mod) + mod) % mod;
  const cyclicId =
    normalizeModulo(Number(currentQuestionId.value) - 1, questionsPanelData.length) + 1;
  return questionsPanelData.find((question) => question.id === cyclicId);
});

// When changing the current question, track a page-view for this question.
watchEffect(() => {
  if (currentQuestion.value) {
    trackQuestionsPageView(currentQuestion.value.id);
  }
});

// Référence pour contrôler l'iframe
const iframeLoading = ref(true);

// Watch currentSpeaker pour contrôler l'iframe
watch(
  currentQuestion,
  async (newQuestion, oldQuestion) => {
    if (newQuestion?.id !== oldQuestion?.id) {
      iframeLoading.value = true;
      await nextTick();
      // La vidéo peut maintenant être mise à jour
      iframeLoading.value = false;
    }
  },
  { immediate: true },
);
</script>

<template>
  <section
    class="panel-questions absolute z-10 flex h-[100dvh] w-screen translate-y-[-100vh] flex-col items-center justify-start gap-[40px] overflow-hidden bg-secondary pt-[110px] lg:flex-row lg:justify-center lg:gap-[70px] lg:pt-0"
  >
    <button
      class="home-question-button button-clip-path absolute left-0 top-0 z-10 h-[223px] w-[223px] translate-x-[-100%] translate-y-[-100%] bg-[#ADE2E3]"
      @click="closePanelQuestions"
    >
      <p
        class="absolute bottom-[13%] right-[21%] flex items-center justify-center gap-[4px] text-[16px] font-[500] text-[#203350]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
        >
          <path
            d="M7.79448 1.26422C7.69677 1.16449 7.58014 1.08525 7.45142 1.03116C7.32269 0.977072 7.18446 0.949219 7.04483 0.949219C6.90521 0.949219 6.767 0.977072 6.63828 1.03116C6.50956 1.08525 6.39293 1.16449 6.29521 1.26422L0.314988 7.27067C0.215257 7.36838 0.136046 7.48502 0.0819569 7.61374C0.0278679 7.74246 0 7.88067 0 8.02029C0 8.15991 0.0278679 8.29814 0.0819569 8.42686C0.136046 8.55558 0.215257 8.67221 0.314988 8.76993L6.32143 14.7764C6.52253 14.9599 6.78698 15.0584 7.05916 15.0511C7.33134 15.0438 7.5901 14.9312 7.78104 14.7371C7.97199 14.543 8.08025 14.2825 8.08311 14.0102C8.08596 13.7379 7.98318 13.4752 7.79634 13.2771L2.55359 8.00811L7.79634 2.76535C7.89607 2.66764 7.97531 2.551 8.0294 2.42228C8.08349 2.29356 8.11136 2.15535 8.11136 2.01573C8.11136 1.87611 8.08349 1.73788 8.0294 1.60916C7.97531 1.48044 7.89607 1.36381 7.79634 1.26609L7.79448 1.26422Z"
            fill="#203350"
          />
        </svg>
        Home
      </p>
    </button>

    <img class="galaxy-panel" src="/assets/images/voeux/galaxy.svg" width="806" height="594" />

    <iframe
      v-if="!iframeLoading && currentQuestion"
      class="panel-questions-element relative h-[210px] w-full max-w-[475px] px-[10px] lg:h-[394px] lg:max-w-[705px]"
      :src="currentQuestion?.video"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowFullScreen="allowFullScreen"
    />

    <div
      class="panel-questions-element relative flex flex-col items-center justify-center gap-[20px]"
    >
      <h2
        class="w-[270px] rounded-[160px] bg-[#00a6b666] px-[24px] py-[3px] text-center font-[700] text-white"
      >
        {{ currentQuestion?.question }}
      </h2>

      <QuestionsCarouselDesktop v-if="windowSize.width.value >= 1024 && currentQuestionId" />

      <QuestionsCarouselMobile v-if="windowSize.width.value < 1024 && currentQuestionId" />
    </div>

    <div
      class="absolute bottom-[3%] flex h-[52px] w-full max-w-[229px] flex-row items-center justify-between gap-[16px] lg:bottom-[5%] lg:max-w-[404px]"
    >
      <button
        class="absolute inset-y-0 right-[-15%] m-auto flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary lg:h-[52px] lg:w-[52px]"
        type="button"
        @click="nextPanelQuestions"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="24"
          viewBox="0 0 14 24"
          fill="none"
          class="w-[6px] lg:w-[14px]"
        >
          <path
            class="fill-white"
            d="M14 12C13.9863 11.5265 13.808 11.1206 13.4378 10.7554L2.76983 0.459977C2.45446 0.162345 2.08423 0 1.63173 0C0.713026 0 0 0.703495 0 1.60992C0 2.04284 0.178257 2.4487 0.493634 2.75986L10.0921 12L0.493634 21.2401C0.178257 21.5513 0 21.9436 0 22.3901C0 23.2965 0.713026 24 1.63173 24C2.07052 24 2.45446 23.8377 2.76983 23.54L13.4378 13.2311C13.8217 12.8794 14 12.4735 14 12Z"
          />
        </svg>
      </button>

      <button type="button" @click="previousPanelQuestions">
        <p class="text-[16px] font-[500] text-white">Previous video</p>
      </button>

      <button type="button" @click="nextPanelQuestions">
        <p class="text-[16px] font-[500] text-white">Next video</p>
      </button>

      <button
        type="button"
        class="absolute inset-y-0 left-[-15%] m-auto flex h-[26px] w-[26px] items-center justify-center rounded-full bg-primary lg:h-[52px] lg:w-[52px]"
        @click="previousPanelQuestions"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="24"
          viewBox="0 0 14 24"
          fill="none"
          class="w-[6px] lg:w-[14px]"
        >
          <path
            d="M0 12C0 12.4735 0.178257 12.8794 0.562194 13.2311L11.2302 23.54C11.5318 23.8377 11.9158 24 12.3683 24C13.2733 24 14 23.2965 14 22.3901C14 21.9436 13.808 21.5513 13.5064 21.2401L3.89422 12L13.5064 2.75986C13.808 2.4487 14 2.04284 14 1.60992C14 0.703495 13.2733 0 12.3683 0C11.9158 0 11.5318 0.162345 11.2302 0.459977L0.562194 10.7554C0.178257 11.1206 0 11.5265 0 12Z"
            class="fill-white"
          />
        </svg>
      </button>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.galaxy-panel {
  height: auto;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(4);
  opacity: 0.2;
  transform-origin: center center;
}
</style>
