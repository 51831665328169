<script lang="ts" setup>
import { useQuestionsPanel } from "@/composables/useQuestionsPanel";

defineProps<{ discovers: { questionId: number; text: string }[] }>();

const { openPanelQuestions } = useQuestionsPanel();
</script>

<template>
  <div class="flex flex-col gap-[40px]">
    <h2 class="text-left text-[20px] font-[500] text-white lg:text-center">
      Discover their wishes:
      <br class="block lg:hidden" />
      select
      <br class="hidden lg:block" />

      a question or
      <br class="block lg:hidden" />

      a speaker!
    </h2>

    <div class="bg-links flex flex-col gap-[20px]">
      <img
        class="header-gif pointer-events-none absolute right-[0px] top-[232px] opacity-0"
        src="/assets/images/voeux/click.gif"
        width="120"
        height="120"
      />

      <button
        v-for="discover in discovers"
        :key="discover.text"
        class="flex w-full max-w-[204px] flex-col justify-center gap-[50px] rounded-[160px] bg-[rgba(0,166,182,0.10)] px-[16px] py-[12px] text-center text-[14px] font-[700] text-white hover:bg-[rgba(0,166,182,0.40)] lg:max-w-full lg:px-[24px] lg:py-[16px] lg:text-[18px]"
        @click="openPanelQuestions(discover.questionId)"
      >
        {{ discover.text }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bg-links {
  background: radial-gradient(
    circle,
    rgba(32, 51, 80, 1) 0%,
    rgba(32, 51, 80, 0.6) 40%,
    rgba(32, 51, 80, 0) 67%
  );
}
</style>
