<script lang="ts" setup>
import Autoplay from "embla-carousel-autoplay";
import emblaCarouselVue from "embla-carousel-vue";
import { onMounted, ref } from "vue";

import { useSpeakersPanel } from "@/composables/useSpeakersPanel";
import { speakersData } from "@/speakersData";

const [emblaRef, emblaApi] = emblaCarouselVue({}, [Autoplay({ playOnInit: true, delay: 4000 })]);

const currentSlideIndex = ref(0);
const { openPanelSpeakerIds } = useSpeakersPanel();

const rings = Object.values(speakersData);
const allSpeakers = rings.flat();

onMounted(() => {
  if (emblaApi.value) {
    emblaApi.value.on("scroll", () => {
      if (emblaApi.value) {
        currentSlideIndex.value = emblaApi.value.selectedScrollSnap();
      }
    });
  }
});
</script>

<template>
  <div class="relative">
    <button
      class="prev absolute left-[-3rem] top-[50%] h-[40px] w-[40px] translate-y-[-50%]"
      type="button"
      @click="
        emblaApi?.scrollPrev();
        emblaApi?.plugins()?.autoplay.stop();
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="24"
        viewBox="0 0 14 24"
        fill="none"
      >
        <path
          d="M0 12C0 12.4735 0.178257 12.8794 0.562194 13.2311L11.2302 23.54C11.5318 23.8377 11.9158 24 12.3683 24C13.2733 24 14 23.2965 14 22.3901C14 21.9436 13.808 21.5513 13.5064 21.2401L3.89422 12L13.5064 2.75986C13.808 2.4487 14 2.04284 14 1.60992C14 0.703495 13.2733 0 12.3683 0C11.9158 0 11.5318 0.162345 11.2302 0.459977L0.562194 10.7554C0.178257 11.1206 0 11.5265 0 12Z"
          fill="#FF67C0"
          :fill-opacity="(currentSlideIndex === 0 && 0.4) || 1"
        />
      </svg>
    </button>

    <button
      class="next absolute right-[-4rem] top-[50%] h-[40px] w-[40px] translate-y-[-50%]"
      @click="
        emblaApi?.scrollNext();
        emblaApi?.plugins()?.autoplay.stop();
      "
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="24"
        viewBox="0 0 14 24"
        fill="none"
      >
        <path
          d="M14 12C13.9863 11.5265 13.808 11.1206 13.4378 10.7554L2.76983 0.459977C2.45446 0.162345 2.08423 0 1.63173 0C0.713026 0 0 0.703495 0 1.60992C0 2.04284 0.178257 2.4487 0.493634 2.75986L10.0921 12L0.493634 21.2401C0.178257 21.5513 0 21.9436 0 22.3901C0 23.2965 0.713026 24 1.63173 24C2.07052 24 2.45446 23.8377 2.76983 23.54L13.4378 13.2311C13.8217 12.8794 14 12.4735 14 12Z"
          fill="#FF67C0"
          :fill-opacity="(currentSlideIndex === 4 && 0.4) || 1"
        />
      </svg>
    </button>

    <div ref="emblaRef" class="embla">
      <div class="embla__container">
        <div
          v-for="speaker in allSpeakers"
          :key="speaker.id"
          class="embla__slide relative flex h-fit flex-col items-center justify-start gap-[16px] lg:hidden"
        >
          <button
            :key="speaker.lastname"
            class="question-speaker flex cursor-pointer flex-col items-center justify-start gap-[5px] text-white"
            @click="openPanelSpeakerIds(speaker.id)"
          >
            <p class="question-speaker-name relative text-center text-[18px] font-[700] uppercase">
              <span class="name-effect relative z-10">{{ speaker.firstname }}&nbsp;</span>

              <span class="name-effect relative z-10">{{ speaker.lastname }}</span>
            </p>

            <p class="text-center text-[14px] font-[300]">
              {{ speaker.role }}
            </p>

            <p class="text-center text-[14px] font-[300]">
              {{ speaker.company }}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.embla {
  max-width: 365px;
  overflow: hidden;
  margin: auto;
  --slide-height: 19rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

@media screen and (max-width: 1024px) {
  .embla {
    max-width: 200px;
  }
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  justify-content: center;
  align-items: center;
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}

.question-speaker {
  .name-effect:after {
    opacity: 0;
    transition: opacity 0.5s;
    position: absolute;
    content: "";
    width: 104%;
    transform: translateX(-2%);
    background-color: theme("colors.primary");
    height: 7px;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
}
.question-speaker:hover {
  .name-effect:after {
    opacity: 1;
  }
}
</style>
