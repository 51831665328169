interface MatomoTracker {
  trackPageView: (title: string) => void;
  trackEvent: (category: string, action: string, name?: string, value?: string | number) => void;
}

interface Matomo {
  getTracker: () => MatomoTracker;
}

export function useMatomo() {
  function getMatomo(): Matomo {
    // eslint-disable-next-line ts/no-unsafe-member-access
    return (window as unknown as any).Matomo as Matomo;
  }

  function trackSpeaker(speakerId: number) {
    const matomo = getMatomo();

    if (matomo) {
      matomo.getTracker().trackEvent("view-speakers", "click", "speaker", speakerId);
    }
  }

  function trackQuestion(questionId: number) {
    const matomo = getMatomo();

    if (matomo) {
      matomo.getTracker().trackEvent("view-questions", "click", "question", questionId);
    }
  }

  function trackQuestionsPageView(questionId: number) {
    const matomo = getMatomo();

    if (matomo) {
      matomo.getTracker().trackPageView(`question-${questionId}`);
    }
  }

  function trackShare() {
    const matomo = getMatomo();

    if (matomo) {
      matomo.getTracker().trackEvent("shares", "click", "share");
    }
  }

  return { trackSpeaker, trackQuestion, trackQuestionsPageView, trackShare };
}
