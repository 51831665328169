<script lang="ts" setup>
import gsap from "gsap";
import { onMounted } from "vue";

import { useSpeakersPanel } from "@/composables/useSpeakersPanel";

// eslint-disable-next-line vue/define-props-declaration
const props = defineProps(["data", "nbOfSpeakers"]);

const { openPanelSpeakerIds } = useSpeakersPanel();

const RINGS_ANIMATION_DURATION = 9;

function onMouseEnter() {
  gsap.to(`.speaker-${props.data.id}`, { scale: 1.1, filter: "grayscale(0%)", duration: 0.1 });
  gsap.to(`.speaker-identity-${props.data.id}`, { opacity: 1 });
  gsap.to(`.speaker-job-${props.data.id}`, { opacity: 1 });
}

function onMouseLeave() {
  gsap.to(`.speaker-${props.data.id}`, { scale: 1, filter: "grayscale(30%)", duration: 0.1 });
  gsap.to(`.speaker-identity-${props.data.id}`, { opacity: 0 });
  gsap.to(`.speaker-job-${props.data.id}`, { opacity: 0 });
}

function useSpeakerAnimation() {
  const timeline = gsap.timeline({ repeat: -1, repeatDelay: 3 * props.nbOfSpeakers });

  timeline
    .to(`.speaker-${props.data.id}`, { scale: 1.1, filter: "grayscale(0%)", duration: 1 }, 0)
    .to(`.speaker-identity-${props.data.id}`, { opacity: 1, duration: 1 }, 0)
    .to(`.speaker-job-${props.data.id}`, { opacity: 1, duration: 1 }, 0)
    .to(`.speaker-${props.data.id}`, { scale: 1, filter: "grayscale(30%)", duration: 1 }, 3)
    .to(`.speaker-identity-${props.data.id}`, { opacity: 0, duration: 1 }, 3)
    .to(`.speaker-job-${props.data.id}`, { opacity: 0, duration: 1 }, 3);

  return timeline;
}

const speakerAnimation = gsap.timeline();

onMounted(() => {
  speakerAnimation.add(useSpeakerAnimation(), 3.5 * props.data.id + RINGS_ANIMATION_DURATION);
});
</script>

<template>
  <div
    class="speaker pointer-events-auto fixed z-50 flex h-[100px] min-w-[100px] max-w-[100px] flex-col items-center opacity-0"
    :style="{ top: `${props.data.position.top}`, left: `${props.data.position.left}` }"
  >
    <p
      :class="[`speaker-identity-${props.data.id}`]"
      class="absolute top-[-60%] w-fit whitespace-nowrap rounded-[8px] bg-primary px-[16px] py-[4px] text-center font-[500] text-white opacity-0"
    >
      {{ props.data.firstname }}&nbsp;{{ props.data.lastname }}
    </p>

    <p
      :class="[`speaker-job-${props.data.id}`]"
      class="absolute bottom-0 w-[120px] translate-y-[115%] rounded-[8px] bg-white bg-opacity-5 p-1 text-center text-[14px] font-[400] text-white opacity-0 backdrop-blur-2xl"
    >
      {{ props.data.company }}
    </p>

    <button type="button" @click="openPanelSpeakerIds(data.id)">
      <img
        class="h-[100px] w-[100px] rounded-full object-cover grayscale-[30%]"
        :class="[`speaker-${props.data.id}`]"
        :src="props.data.picture"
        :alt="`picture of ${props.data.firstname} ${props.data.lastname}`"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
      />
    </button>
  </div>
</template>

<style lang="scss" scoped></style>
