import { initSentry, SentryEnvironment, type SentryOptions } from "@team-uep/vue-sentry";
import sentryConfig from "virtual:sentryclirc";

import { isDevEnv, isStagingEnv } from "@/helpers/context";

import Package from "../package.json";

function getSentryEnv() {
  if (isDevEnv()) {
    return SentryEnvironment.Development;
  }

  if (isStagingEnv()) {
    return SentryEnvironment.Staging;
  }

  return SentryEnvironment.Production;
}

const ignoreErrors = ["Error: Failed to load iubenda script: event error", "GVLError"];

const onBeforeSend: SentryOptions["beforeSend"] = (event, hint) => {
  const error = hint.originalException;

  // Related to Google ReCAPTCHA timeout errors, they reject with a primitive value instead of a `new Error("timeout")`.
  // See: https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
  if (error === "Timeout") {
    // Overriding fingerprint allow for better error grouping.
    event.fingerprint = ["recaptcha-timeout"];
    event.message = (hint.originalException as string | undefined) ?? "Google ReCAPTCHA timeout";
    // Can be considered as a warning since the end user is not impacted.
    event.level = "warning";
  }

  // If the error is an instance of Error, try to match the message with a list of partial messages.
  if (error instanceof Error) {
    const partialMessages = [
      // Microsoft Outlook Crawler error when trying to scan links within emails with an headless browser.
      "Object Not Found Matching Id:",
      // Safari extension error.
      "safari-extension",
      // Safari iOS extension error.
      "safari-web-extension",
      // Chrome extension error.
      "chrome-extension",
      // Firefox extension error.
      "moz-extension",
      // Safari (WebKit) internal issues
      "TypeError: WebKit",
      // Firefox internal errors
      "NS_ERROR_",
      // Firefox internal errors
      "__firefox__",
    ];

    if (partialMessages.some((message) => error.message.includes(message))) {
      return null;
    }
  }

  return event;
};

export function loadSentry(app: SentryOptions["app"], router: SentryOptions["vueRouter"]) {
  initSentry({
    app,
    vueRouter: router,
    projectName: Package.name,
    projectVersion: Package.version,
    sentryclirc: sentryConfig.sentryclirc,
    projectEnv: getSentryEnv(),
    beforeSend: onBeforeSend,
    ignoreErrors,
  });
}

export function loadSentryVanilla() {
  initSentry({
    projectName: Package.name,
    projectVersion: Package.version,
    sentryclirc: sentryConfig.sentryclirc,
    projectEnv: getSentryEnv(),
    beforeSend: onBeforeSend,
    ignoreErrors,
  });
}
