import { useRouteQuery } from "@vueuse/router";
import { gsap } from "gsap";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useMatomo } from "./useMatomo";

export function useSpeakersPanel() {
  const currentSpeakerId = useRouteQuery<string>("speaker");
  const currentQuestionId = useRouteQuery<string>("question");
  const router = useRouter();
  const speakerIdToDisplay = ref(currentSpeakerId);
  const { trackSpeaker } = useMatomo();

  async function closePanelSpeakerIds() {
    await router.replace({ query: {} });
    const timeline = gsap.timeline();

    timeline.to(".home-speaker-button", { translate: "0% 0%", duration: 0.5 }, 0).to(
      ".panel-speakers",
      {
        translateY: "-100dvh",
        duration: 1.5,
        ease: "power3.out",
      },
      0,
    );
  }

  async function openPanelSpeakerIds(id: number) {
    await router.replace({ query: { speaker: id, question: currentQuestionId.value } });
    const timeline = gsap.timeline();

    timeline
      .to(".panel-speakers", {
        translateY: "0dvh",
        duration: 1,
        ease: "power3.out",
      })
      .to(".home-speaker-button", { translate: "50% 35%", duration: 0.5 }, 0);

    trackSpeaker(id);
  }

  function nextPanelSpeakerIds() {
    const timeline = gsap.timeline();

    timeline
      .to(".panel-speakers-element", {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          speakerIdToDisplay.value = String((Number(speakerIdToDisplay.value) % 9) + 1);
        },
      })
      .to(".panel-speakers-element", { opacity: 1, duration: 0.5 });

    trackSpeaker((Number(speakerIdToDisplay.value) % 9) + 1);
  }
  function previousPanelSpeakerIds() {
    const timeline = gsap.timeline();

    timeline
      .to(".panel-speakers-element", {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          speakerIdToDisplay.value = String((Number(speakerIdToDisplay.value) - 1 + 9) % 9 || 9);
        },
      })
      .to(".panel-speakers-element", { opacity: 1, duration: 0.5 });

    trackSpeaker((Number(speakerIdToDisplay.value) - 1 + 9) % 9 || 9);
  }

  watch(
    currentSpeakerId,
    async () => {
      if (currentSpeakerId.value) {
        await openPanelSpeakerIds(Number(currentSpeakerId.value));
      } else {
        await closePanelSpeakerIds();
      }
    },
    { immediate: true },
  );

  return {
    previousPanelSpeakerIds,
    closePanelSpeakerIds,
    openPanelSpeakerIds,
    nextPanelSpeakerIds,
  };
}
