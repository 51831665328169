<script setup lang="ts">
import { useIubenda } from "@team-uep/vue-iubenda-op";
import { useWindowSize } from "@vueuse/core";
import { gsap } from "gsap";
import { computed, ref, useTemplateRef, watch } from "vue";

import Modal from "@/components/modals/Modal.vue";
import PanelQuestions from "@/components/voeux/PanelQuestions.vue";
import PanelSpeakers from "@/components/voeux/PanelSpeakers.vue";
import Sidebar from "@/components/voeux/Sidebar.vue";
import SiteFooter from "@/components/voeux/SiteFooter.vue";
import SpeakerMap from "@/components/voeux/SpeakerMap.vue";

const iubenda = useIubenda();

if (iubenda) {
  iubenda.initialize({
    csConfiguration: {
      consentOnContinuedBrowsing: false,
      enableTcf: true,
      floatingPreferencesButtonDisplay: "bottom-right",
      googleAdditionalConsentMode: true,
      invalidateConsentWithoutLog: true,
      lang: "en",
      newConsentAtVendorListUpdate: 0,
      perPurposeConsent: true,
      siteId: 2553297,
      whitelabel: false,
      cookiePolicyId: 55538966,
      banner: {
        acceptButtonDisplay: true,
        closeButtonRejects: true,
        customizeButtonDisplay: true,
        explicitWithdrawal: true,
        position: "float-top-center",
        rejectButtonDisplay: true,
      },
    },
    buttonColor: "#00A6B6",
  });
}

const windowSize = useWindowSize();

const isDesktopAnimation = computed(() => {
  return windowSize.width.value > 1024;
});

const galaxyRef = ref<HTMLImageElement | null>(null);

const mainWrapperRef = useTemplateRef("mainWrapper");
const sidebarRef = useTemplateRef("sidebar");

const isNewYear = computed(() => {
  const today = new Date();
  const newYearDate = new Date(2025, 0, 1);
  return today >= newYearDate;
});

const GALAXY_IMAGE_WIDTH = 806;
const GALAXY_IMAGE_HEIGHT = 594;
const MOVE_TO_RIGHT_MAP = isDesktopAnimation.value ? 100 : 0;
const MOBILE_GALAXY_SCALE_FACTOR = 3;
const DESKTOP_GALAXY_SCALE_FACTOR = 1.5;

function useGalaxyAnimation(galaxyElement: HTMLImageElement) {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const galaxyScaleX = screenWidth / GALAXY_IMAGE_WIDTH;
  const galaxyScaleY = screenHeight / GALAXY_IMAGE_HEIGHT;

  const galaxyScale = isDesktopAnimation.value
    ? Math.max(galaxyScaleX, galaxyScaleY) * DESKTOP_GALAXY_SCALE_FACTOR
    : Math.max(galaxyScaleX, galaxyScaleY) * MOBILE_GALAXY_SCALE_FACTOR;

  const galaxyAnimationTimeline = gsap.timeline();

  galaxyAnimationTimeline
    .to(galaxyElement, {
      scale: galaxyScale,
      duration: 7.5,
      onComplete: () => {
        gsap.to(galaxyElement, { display: "none" });
      },
    })
    .to(".text-opening", { opacity: 0, duration: 1 }, 1.7)
    .to(galaxyElement, { rotate: 45, duration: 3.7 }, 2.7)
    .to(".text-discover", { opacity: 1, duration: 0.5 }, 2.7)
    .to(".text-discover", { opacity: 0, duration: 0.5 }, 4.7)
    .to(".text-end", { opacity: 1, duration: 0.5 }, 6.3)
    .to(".speaker", { opacity: 1, duration: 0.5 }, 6.2)
    .to(galaxyElement, { opacity: 0, duration: 0.3 }, 8.0);

  return galaxyAnimationTimeline;
}

function useRingsAnimation() {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;

  const ringsScaleX = screenWidth / (1119 + MOVE_TO_RIGHT_MAP);
  const ringsScaleY = screenHeight / 1051;

  const ringsScale = isDesktopAnimation.value
    ? Math.min(ringsScaleX * 0.8, ringsScaleY * 1.2)
    : Math.min(ringsScaleX * 1.7, ringsScaleY * 1.7);
  const ringsAnimationTimeline = gsap.timeline();

  ringsAnimationTimeline
    .to(".speaker-rings", { opacity: 1, duration: 0.5 })
    .to(".speaker-rings", {
      scale: ringsScale,
      duration: 2.5,
      ease: "back.out(2.5)",
    })
    .to(
      ".sidebar-desktop",
      {
        scale: Math.min(1, ringsScale),
      },
      0,
    )
    .to(".first-ring", { rotate: "360deg", duration: 140, repeat: -1, ease: "linear" }, 0)
    .to(".first-ring-rotate", { rotate: "360deg", duration: 140, repeat: -1, ease: "linear" }, 0)
    .to(".second-ring", { rotate: "-360deg", duration: 140, repeat: -1, ease: "linear" }, 0)
    .to(".second-ring-rotate", { rotate: "-360deg", duration: 140, repeat: -1, ease: "linear" }, 0)
    .to(".third-ring", { rotate: "360deg", duration: 140, repeat: -1, ease: "linear" }, 0)
    .to(
      ".speaker-anim-second-ring",
      {
        rotate: "360deg",
        duration: 140,
        repeat: -1,
        ease: "linear",
      },
      0,
    )
    .to(
      ".speaker-anim-first-ring",
      {
        rotate: "-360deg",
        duration: 140,
        repeat: -1,
        ease: "linear",
      },
      0,
    )
    .to(
      ".speaker-rings-to-right",
      {
        translateX: `${MOVE_TO_RIGHT_MAP}px`,
        duration: 3,
      },
      3.2,
    );
  return ringsAnimationTimeline;
}

function useMobileHeaderAnimation() {
  const timeline = gsap.timeline();

  timeline
    .to(".header-logo", { translateX: "0%", duration: 1 })
    .to(".header-button", { translateX: "0%", duration: 1 }, 0)
    .to(".header-gif", { opacity: 1, duration: 1 });

  return timeline;
}

function useTextEndAnimation() {
  const timeline = gsap.timeline({ repeat: 2, yoyo: true });

  timeline
    .to(".text-end-child", {
      background:
        "linear-gradient(89deg, #FFF 38.11%, rgba(0, 166, 182, 0.71) 59.61%, #85F4FF 82.82%)",
      duration: 0.5,
    })
    .to(".text-end-child", {
      background:
        "linear-gradient(89deg, #85F4FF 38.11%, rgba(0, 166, 182, 0.71) 59.61%, #FFF 82.82%)",
      duration: 0.5,
    });

  return timeline;
}

function useSidebarAnimation() {
  const timeline = gsap.timeline();

  timeline
    .to(".sidebar-desktop", { translateX: "0%", duration: 3.2 })
    .to(".sidebar-desktop", { opacity: 1, duration: 1.5 }, 0);

  return timeline;
}

function useGalaxyPanelAnimation() {
  const timeline = gsap.timeline({ yoyo: true, repeat: -1 });

  timeline.to(".galaxy-panel", { rotate: "25%", duration: 10 });
  timeline.to(".galaxy-panel", { rotate: "0%", duration: 10 });

  return timeline;
}

const mainTimeline = gsap.timeline();
watch(
  galaxyRef,
  async (galaxyValue) => {
    if (galaxyValue) {
      const galaxyTimeline = useGalaxyAnimation(galaxyValue);
      const ringsTimeline = useRingsAnimation();
      const sidebarTimeline = useSidebarAnimation();
      const textEndTimeline = useTextEndAnimation();
      const mobileHeaderTimeline = useMobileHeaderAnimation();

      mainTimeline
        .add(galaxyTimeline)
        .add(ringsTimeline, 4.3)
        .add(textEndTimeline, 5.2)
        .add(sidebarTimeline, 7.5)
        .add(mobileHeaderTimeline, 8.3)
        .to(".skip-button", { translateX: "100%", translateY: "-100%", duration: 0.5 }, 7.9);

      useGalaxyPanelAnimation();
    }
  },
  { flush: "post" },
);

// Mobile center rings
watch(
  [windowSize.width, mainWrapperRef],
  () => {
    if (mainWrapperRef.value) {
      mainWrapperRef.value.scrollTo({ left: windowSize.width.value / 2 });
    }
  },
  { flush: "post" },
);
</script>

<template>
  <main class="max-w-screen relative overflow-hidden bg-secondary lg:max-w-['unset']">
    <PanelQuestions />

    <PanelSpeakers />

    <div
      ref="mainWrapper"
      class="relative h-[100dvh] w-full overflow-y-hidden overflow-x-scroll scrollbar-hide lg:overflow-hidden"
    >
      <img
        ref="galaxyRef"
        class="galaxy"
        src="/assets/images/voeux/galaxy.svg"
        width="806"
        height="594"
      />

      <h1 v-if="isNewYear" class="text-opening">
        <span>Numberly & friends</span>

        <span>wish you a Happy New Year!</span>
      </h1>

      <h1 v-else class="text-opening">
        <span>Numberly & friends</span>

        <span>wish you Happy Holidays!</span>
      </h1>

      <p class="text-discover">
        Discover the video
        <br />
        messages left by some of
        <br />
        our most inspiring friends...
      </p>

      <div class="speaker-rings-to-right">
        <SpeakerMap>
          <div class="text-end">
            <p class="text-end-child w-full px-[200px] leading-[30px]">
              Our best
              <br />
              wishes for
            </p>

            <br />

            <p class="text-end-child px-[200px] text-[61px] leading-[85%]">2025</p>
          </div>
        </SpeakerMap>
      </div>
    </div>

    <!-- Mobile Header -->
    <div class="absolute top-0 flex w-full justify-between pl-[20px] lg:hidden">
      <img
        src="/assets/images/voeux/logo_numberly.svg"
        alt="numberly"
        class="header-logo translate-x-[-120%]"
        width="109"
      />

      <button
        class="header-button translate-x-[100%] rounded-bl-[20px] bg-primary py-[18px] pl-[19px] pr-[11px] text-white"
        type="button"
        @click="sidebarRef?.onSidebarOpenClick"
      >
        Discover their wishes
        <img
          class="header-gif absolute bottom-[-60px] left-[-30px] opacity-0"
          src="/assets/images/voeux/click.gif"
          width="120"
          height="120"
        />
      </button>
    </div>

    <Sidebar v-if="!isDesktopAnimation" ref="sidebar" />

    <button
      class="skip-button button-clip-path absolute right-0 top-0 h-[223px] w-[223px] translate-x-[50%] translate-y-[-50%] bg-[#ADE2E3]"
      @click="mainTimeline.progress(0.99)"
    >
      <p
        class="absolute bottom-[25%] left-[21%] flex items-center justify-center gap-[4px] text-[16px] font-[500] text-[#203350]"
      >
        Skip
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="16"
          viewBox="0 0 9 16"
          fill="none"
        >
          <path
            d="M0.963328 1.26422C1.06104 1.16449 1.17768 1.08525 1.3064 1.03116C1.43512 0.977072 1.57336 0.949219 1.71298 0.949219C1.8526 0.949219 1.99081 0.977072 2.11953 1.03116C2.24825 1.08525 2.36489 1.16449 2.4626 1.26422L8.44282 7.27067C8.54256 7.36838 8.62177 7.48502 8.67586 7.61374C8.72994 7.74246 8.75781 7.88067 8.75781 8.02029C8.75781 8.15991 8.72994 8.29814 8.67586 8.42686C8.62177 8.55558 8.54256 8.67221 8.44282 8.76993L2.43638 14.7764C2.23528 14.9599 1.97083 15.0584 1.69865 15.0511C1.42647 15.0438 1.16772 14.9312 0.976768 14.7371C0.78582 14.543 0.677563 14.2825 0.674706 14.0102C0.671849 13.7379 0.774636 13.4752 0.961469 13.2771L6.20422 8.00811L0.961469 2.76535C0.861738 2.66764 0.782499 2.551 0.72841 2.42228C0.674321 2.29356 0.646453 2.15535 0.646453 2.01573C0.646453 1.87611 0.674321 1.73788 0.72841 1.60916C0.782499 1.48044 0.861738 1.36381 0.961469 1.26609L0.963328 1.26422Z"
            fill="#203350"
          />
        </svg>
      </p>
    </button>

    <SiteFooter />

    <Modal />
  </main>
</template>

<style lang="scss">
.galaxy {
  height: auto;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  transform: scale(0.1);
  transform-origin: center center;
}
@media screen and (max-width: 1024px) {
  .galaxy {
    left: 50%;
    transform: translate(-50%, -50%);
    transform: scale(0.1);
  }
}

.text-opening {
  background: linear-gradient(89deg, #fff 38.11%, rgba(0, 166, 182, 0.71) 59.61%, #85f4ff 82.82%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 50px;
  width: 100%;
  gap: 20px;
}
@media screen and (max-width: 1024px) {
  .text-opening {
    left: 100%;
    font-size: 24px;
  }
}

.text-discover {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .text-discover {
    left: 100%;
    font-size: 20px;
  }
}

.text-end {
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.text-end-child {
  display: inline-block;
  background: linear-gradient(88deg, #fff 29.71%, #00a6b6 49.07%, rgba(133, 244, 255, 0.6) 66.16%);
  background-clip: text !important;
  -webkit-background-clip: text;
  color: transparent;
}

.button-clip-path {
  clip-path: circle(50% at 50% 50%);
}
</style>
