<script setup lang="ts">
import { computed } from "vue";
import { RouterView, useRoute } from "vue-router";

const route = useRoute();

const transitionName = computed(() => {
  if (route.meta?.transition) {
    return route.meta.transition as string;
  }

  return "";
});
</script>

<template>
  <Transition :name="transitionName">
    <RouterView />
  </Transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
