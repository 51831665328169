<script lang="ts">
import type { Iubenda } from "@team-uep/vue-iubenda-op";
import { defineComponent, inject, onMounted } from "vue";

export default defineComponent({
  name: "IubendaPrivacyPolicyModal",

  emits: ["updateSize"],

  setup(props, { emit }) {
    const iubenda = inject("iubenda") as Iubenda;

    onMounted(() => {
      emit("updateSize", "max");
    });

    return { iubenda };
  },
});
</script>

<template>
  <div>
    <!-- vuepress@iubenda-get-privacy-policy-url -->
    <iframe
      class="cookieModal"
      :src="iubenda.getPrivacyPolicyURL()"
      scrolling="yes"
      frameborder="0"
      allowtransparency="true"
      title="iubenda"
    />
    <!-- vuepress@iubenda-get-privacy-policy-url -->
  </div>
</template>

<style lang="scss" scoped>
.cookieModal {
  width: calc(100% + 50px);
  height: 90vh;
  margin: -25px 0 0 -25px;
}
</style>
