<script setup lang="ts">
import { type Link, NFooter } from "@team-uep/panoply/n-footer";
import type { Iubenda } from "@team-uep/vue-iubenda-op";
import { computed, inject } from "vue";

import useModal from "@/composables/useModal";

const iubenda = inject("iubenda") as Iubenda;
const { openModal } = useModal();

const footerLink = computed<Link[]>(() => [
  {
    id: "iubenda-cookies-preferences-cta",
    text: `Cookies management`,
    typeOfHtmlComponent: "button",
  },
  {
    id: "cookies-privacy-policy-cta",
    text: "Cookies use policy",
    typeOfHtmlComponent: "button",
  },
  {
    id: "contact-cta",
    text: "Contact us",
    typeOfHtmlComponent: "a",
    anchorAttributes: {
      href: `mailto:contact@webinar.numberly.com?subject=Numberly's wishes 2025`,
      target: "_blank",
      rel: "noopener noreferrer",
    },
  },
  {
    id: "realisation-cta",
    text: "Copyright",
  },
]);
</script>

<template>
  <!-- :mentions="t('footer.mentions')" -->
  <NFooter
    mentions=""
    :links="footerLink"
    @termsOpenCta="openModal('Terms')"
    @iubendaCookiesPreferencesCta="iubenda.openPreferencesModal()"
    @cookiesPrivacyPolicyCta="openModal('IubendaPrivacyPolicy')"
    @realisationCta="openModal('Realisation')"
  />
</template>

<style lang="scss" scoped>
.n-footer {
  background: white;
  padding-bottom: 0;
}
:deep(.n-cta) {
  border-radius: 0px;
}
:deep(.link-separator) {
  color: black;
}
:deep(.n-cta.link) {
  color: black;
  font-size: 14px;
  font-weight: 400;
}
</style>
