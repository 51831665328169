import "./assets/base.css";
import "./assets/fonts.css";

import iubendaPlugin from "@team-uep/vue-iubenda-op";
import { createPinia } from "pinia";
import sentryConfig from "virtual:sentryclirc";
import { createApp } from "vue";
import VueMatomo from "vue-matomo";

import { isDebugMode, isLocalRun } from "@/helpers/context";

import App from "./App.vue";
import { initializeI18n } from "./i18n";
import router from "./router";
import { loadSentry } from "./sentry";

const app = createApp(App);
const pinia = createPinia();

// Configure Sentry
if (!isDebugMode() && !isLocalRun()) {
  if (sentryConfig.fileExists) {
    loadSentry(app, router);
  } else {
    console.error(
      "Sentry is not configured, please take a look at your project merge-requests to merge the Sentry config file (.sentryclirc) in your project.\nThis merge-request should be created from the GitLab bot.",
    );
  }
}

app.use(pinia);
app.use(router);
app.use(iubendaPlugin);
// eslint-disable-next-line ts/no-unsafe-argument
app.use(VueMatomo, {
  host: "https://numberly.matomo.cloud",
  siteId: 3,
  trackerUrl: "https://numberly.matomo.cloud/matomo.php",
  router,
});
app.use(initializeI18n(() => app.mount("#app")));
