import { UAParser } from "ua-parser-js";

function normalizeHostname(url: string): string {
  if (url.indexOf("http") === 0) {
    return new URL(url).hostname;
  }
  return url;
}

/* When on Vitest, we want to try different countries & languages */
export function isVitest() {
  return import.meta.env.VITEST === "true";
}

export function isMsw() {
  return import.meta.env.MODE === "msw";
}

export function isLocalRun() {
  return (
    window.location.hostname.includes("127.0.0.1") || window.location.hostname.includes("localhost")
  );
}
/**
 * Check app running in development by detecting `localhost` or dev URL keyword in the URL.
 *
 */
export function isLocalEnv(hostname = window.location.hostname): boolean {
  const urls = ["localhost", ".localhost", "127.0.0.1", ".127.0.0.1"];
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return normalizeHostname(hostname).search(regexp) > -1;
}
/**
 * Check app running in development by detecting `localhost` or dev URL keyword in the URL.
 *
 */
export function isDevEnv(hostname = window.location.hostname): boolean {
  const urls = ["localhost", ".localhost", "127.0.0.1", ".127.0.0.1", ".numberly.dev"];
  // eslint-disable-next-line regexp/no-unused-capturing-group
  const regexp = new RegExp(`^(${urls.join(")|(")})$`);
  return normalizeHostname(hostname).search(regexp) > -1;
}

/**
 * Detect app running in staging by detecting `numberly.st` keyword in the URL.
 *
 */
export function isStagingEnv(hostname = window.location.hostname): boolean {
  const numberlyStaging = ".numberly.st";
  return hostname.includes(numberlyStaging);
}

/**
 * Detect app running in production by checking if not staging or development environment.
 *
 */
export function isProdEnv(hostname = window.location.hostname): boolean {
  return !isDevEnv(hostname) && !isStagingEnv(hostname);
}

/**
 * Detect app running in debug mode.
 * To activate debug mode add param debug=1 to query string.
 */
export function isDebugMode(): boolean {
  return /debug=/i.test(window.location.search) && !isProdEnv();
}

/**
 * Is in iframe context.
 *
 * @returns {boolean} True if the code is running inside an iframe
 */
export function isIframe(): boolean {
  try {
    return window.self !== window.top;
  } catch {
    return true;
  }
}

/**
 * Is on tablet device
 */
export function isTabletDevice(): boolean {
  const parser = new UAParser();
  return parser.getResult().device.type === "tablet";
}

/**
 * Is on mobile device
 */
export function isMobileDevice(): boolean {
  const parser = new UAParser();
  return parser.getResult().device.type === "mobile";
}

/**
 * Is on desktop device
 */
export function isDesktopDevice(): boolean {
  return !isTabletDevice() && !isMobileDevice();
}

/**
 * Is in Facebook webview
 */
export function isFacebookBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("fban") || userAgent.includes("fbav");
}

/**
 * Is in Instagram webview
 */
export function isInstagramBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("instagram");
}

/**
 * Is in Twitter webview
 */
export function isTwitterBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("twitter");
}

/**
 * Is in Snapchat webview
 */
export function isSnapchatBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("snapchat");
}

/**
 * Is in Linkedin webview
 */
export function isLinkedinBrowser(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("linkedin");
}

/**
 * Is in webview
 */
export function isWebViewBrowser(): boolean {
  const webviews = [
    "WebView",
    // iOS webview will be the same as Safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
    // Old chrome android webview agent
    "Linux; U; Android",
    "(Facebook|Messenger|MESSENGER|MessengerForiOS)",
    "Instagram",
    "LinkedInApp",
  ];

  // eslint-disable-next-line regexp/no-unused-capturing-group, regexp/no-dupe-disjunctions
  const regex = new RegExp(`(${webviews.join("|")})`, "gi");

  return !!navigator.userAgent.match(regex);
}

/**
 * Detect iOS
 */
export function isIOS(): boolean {
  const list = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
  return (
    list.includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

/**
 * Detect Android OS
 */
export function isAndroid(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes("android");
}
