<script lang="ts">
import { NModal } from "@team-uep/panoply/n-modal";
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref } from "vue";

import useIubendaShown from "@/composables/useIubendaShown";
import useModal from "@/composables/useModal";
import modalEventEmitter from "@/helpers/modalEventEmitter";

import IubendaPrivacyPolicyModal from "./IubendaPrivacyPolicyModal.vue";
import RealisationModal from "./RealisationModal.vue";

export default defineComponent({
  name: "Modal",
  components: {
    NModal,
    RealisationModal,
    IubendaPrivacyPolicyModal,
  },
  setup() {
    const initialValues = reactive({
      size: "min",
    });

    const {
      close: closeQuery,
      displayModal: displayQueryModal,
      currentModal: currentQueryModal,
      childrenRef: queryModalChildreRef,
    } = useModal();

    const { isIubendaShown } = useIubendaShown();
    const displaySilentModal = ref(false);
    const currentSilentModal = ref("");
    const silentModalChildreRef = ref("");

    const onOpenSilentModal = (modalName: string, childrenRef: string) => {
      // Block other modals display if offline
      if (displaySilentModal.value === true && currentSilentModal.value === "Offline") {
        return;
      }
      displaySilentModal.value = true;
      currentSilentModal.value = modalName;
      silentModalChildreRef.value = childrenRef;
    };

    const onCloseSilentModal = () => {
      displaySilentModal.value = false;
      currentSilentModal.value = "";
      silentModalChildreRef.value = "";
    };

    onMounted(() => {
      modalEventEmitter.on("open", onOpenSilentModal);
      modalEventEmitter.on("close", onCloseSilentModal);
    });

    onUnmounted(() => {
      modalEventEmitter.off("open", onOpenSilentModal);
      modalEventEmitter.off("close", onCloseSilentModal);
    });

    const updateSize = (newSize: string): void => {
      initialValues.size = newSize || "min";
    };

    const currentModal = computed(() => currentSilentModal.value || currentQueryModal.value);
    const displayModal = computed(() => displaySilentModal.value || displayQueryModal.value);
    const childrenRef = computed(() => silentModalChildreRef.value || queryModalChildreRef.value);

    const close = () => {
      if (displaySilentModal.value) {
        modalEventEmitter.close(currentSilentModal.value);
      } else {
        closeQuery();
      }
    };

    return {
      initialValues,
      currentModal,
      displayModal,
      childrenRef,
      close,
      updateSize,
      isIubendaShown,
    };
  },
});
</script>

<template>
  <NModal
    :size="initialValues.size"
    :visible="displayModal"
    :isFocusTrapDisabled="isIubendaShown"
    @close="close"
  >
    <Component
      :is="`${currentModal}Modal`"
      :id="`${currentModal}Modal`"
      :ref="childrenRef"
      data-cy="modal-content"
      @updateSize="updateSize"
      @close="close"
    />
  </NModal>
</template>
