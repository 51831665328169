<script lang="ts" setup>
import gsap from "gsap";

import SidebarLinks from "./SidebarLinks.vue";

const discovers = [
  { text: "What do you wish you had known 25 years ago?", questionId: 1 },
  { text: "What do you wish for the digital world in 2025?", questionId: 2 },
  { text: "What is one wish of yours that has come true in the past 25 years?", questionId: 3 },
  { text: "What impact do you wish to have on your ecosystem in 2025?", questionId: 4 },
  { text: "What would you wish for Numberly in 2025?", questionId: 5 },
];

function onSidebarCloseClick() {
  gsap.to(".sidebar-mobile", { translateX: "100%", duration: 1 });
}

function onSidebarOpenClick() {
  gsap.to(".sidebar-mobile", { translateX: "0%", duration: 1 });
}

defineExpose({
  onSidebarOpenClick,
});
</script>

<template>
  <!-- DESKTOP -->
  <div
    class="sidebar-desktop absolute left-[0%] top-[50%] hidden w-[350px] translate-x-[-100%] translate-y-[-50%] flex-col items-center justify-center gap-[50px] opacity-0 lg:flex"
  >
    <img src="/assets/images/voeux/logo_numberly.svg" alt="numberly" width="219" height="56" />

    <SidebarLinks :discovers />
  </div>

  <!-- MOBILE -->
  <div
    class="sidebar-mobile absolute right-[0] top-[0] flex h-[100dvh] w-[70%] max-w-[350px] translate-x-[100%] flex-col items-center justify-start gap-[50px] bg-secondary pt-[40px] lg:hidden"
  >
    <button type="button" class="absolute right-2 top-2" @click="onSidebarCloseClick">
      <svg
        class=""
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Close1" clip-path="url(#clip0_6165_1847)">
          <path
            id="layer1"
            d="M15 1.00127L1 15M15 15L1.00064 1"
            stroke="white"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </g>

        <defs>
          <clipPath id="clip0_6165_1847">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>

    <SidebarLinks :discovers />
  </div>
</template>

<style lang="scss" scoped></style>
