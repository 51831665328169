export const speakersData = {
  thirdRing: [
    {
      id: 1,
      firstname: "Frédéric",
      lastname: "Oudéa",
      company: "Sanofi",
      role: "Chairman of the board",
      wishVideo: "https://www.youtube.com/embed/LR44BD1upmw?si=ORoGyagyuATxmDUN",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-1.jpg",
      position: {
        top: "34%",
        left: "57%",
      },
    },
    {
      id: 2,
      firstname: "Katharina",
      lastname: "Kloss",
      company: "ARTE",
      role: "Head of ARTE in 6 languages",
      wishVideo: "https://www.youtube.com/embed/bVTe4_OkHF4?si=30xOvqFuCL_b0fy7",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-2.jpg",
      position: {
        top: "49%",
        left: "31%",
      },
    },
    {
      id: 3,
      firstname: "Boaz",
      lastname: "Paldi",
      company: "United Nations Development Programme",
      role: "Chief Creative Officer",
      wishVideo: "https://www.youtube.com/embed/8CsNc1cXPbY?si=SmuJuGbgSTT0p2__",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-3.jpg",
      position: {
        top: "61%",
        left: "55%",
      },
    },
  ],
  secondRing: [
    {
      id: 4,
      firstname: "Karine",
      lastname: "Dussert‑Sarthe",
      company: "Orange",
      role: "Managing Director Regional Paris",
      wishVideo: "https://www.youtube.com/embed/Dt_mTTxcg_c?si=5j5d8rvRYB1P_2aD",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-4.jpg",
      position: {
        top: "18%",
        left: "57%",
      },
    },
    {
      id: 5,
      firstname: "Frédéric",
      lastname: "Mazzella",
      company: "BlaBlaCar & Dift",
      role: "Founder",
      wishVideo: "https://www.youtube.com/embed/ZweVPeE3t3U?si=BLqyydn6Smj9TSHG",
      webinarLink: "https://numberly.com/fr/tv-day/#",
      picture: "/assets/images/voeux/speaker-5.jpg",
      position: {
        top: "40%",
        left: "75%",
      },
    },
    {
      id: 6,
      firstname: "Katie",
      lastname: "Stanton",
      company: "Moxxie Ventures",
      role: "Founder & General Partner",
      wishVideo: "https://www.youtube.com/embed/1D-tgDHOMfg?si=vxgkPrTuejtZyxA_",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-6.jpg",
      position: {
        top: "66%",
        left: "70%",
      },
    },
    {
      id: 7,
      firstname: "Ethan",
      lastname: "Heftman",
      company: "Ampersand",
      role: "Chief Revenue Officer",
      wishVideo: "https://www.youtube.com/embed/nNnjWTpDPok?si=ALXMhYqDrRENxMNo",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-7.jpg",
      position: {
        top: "78%",
        left: "41%",
      },
    },
    {
      id: 8,
      firstname: "Isabelle",
      lastname: "Huault",
      company: "emlyon",
      role: "Chairwoman of the board and General Director",
      wishVideo: "https://www.youtube.com/embed/0bG1z-WV0pM?si=cMTRdKM7Bs97jEAN",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-8.jpg",
      position: {
        top: "65%",
        left: "22%",
      },
    },
    {
      id: 9,
      firstname: "Vihan",
      lastname: "Sharma",
      company: "LiveRamp",
      role: "Chief Revenue Officer",
      wishVideo: "https://www.youtube.com/embed/wjhxl5dPU6g?si=Dnpaq6onK-5TtET3",
      webinarLink: "",
      picture: "/assets/images/voeux/speaker-9.jpg",
      position: {
        top: "39%",
        left: "18%",
      },
    },
  ],
  firstRing: [],
};
