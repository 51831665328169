<script lang="ts" setup>
import { computed } from "vue";

import NumberlyInternationalLogo from "@/assets/logo/numberly-international.svg";

const numberlyInternationalLogoSrc = NumberlyInternationalLogo;

const numberlyLogo = computed(() => {
  return numberlyInternationalLogoSrc;
});
</script>

<template>
  <div class="text-center">
    <p>
      <a
        href="https://numberly.com/fr"
        target="_blank"
        rel="noopener noreferrer"
        title="numberly website"
      >
        <img
          class="mx-auto block max-w-xs"
          :src="numberlyLogo"
          alt="image numberly logo"
          width=""
          height=""
        />
      </a>
    </p>

    <p v-sanitize="'28, rue de Châteaudun<br/>75009 Paris - France'" />

    <p>
      <a
        href="https://www.numberly.com"
        target="_blank"
        rel="noopener noreferrer"
        title="numberly website"
      >
        www.numberly.com
      </a>

      <br />

      <span>Do not hesitate to contact us.</span>

      <br />

      <span>Email</span>
      :

      <a
        class="text-primary"
        data-cy="mailto-contact"
        title="mailto contact"
        href="mailto:kate@numberly.com?subject=Numberly's wishes 2025"
        target="_blank"
        rel="noopener noreferrer"
      >
        kate@numberly.com
      </a>
    </p>
  </div>
</template>

<style lang="scss"></style>
