import { useRouteQuery } from "@vueuse/router";
import { gsap } from "gsap";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";

import { useMatomo } from "./useMatomo";

export function useQuestionsPanel() {
  const currentQuestionId = useRouteQuery<string>("question");
  const currentSpeakerId = useRouteQuery<string>("speaker");
  const questionToDisplay = ref(currentQuestionId);
  const router = useRouter();
  const { trackQuestion } = useMatomo();

  async function closePanelQuestions() {
    await router.replace({ query: { speaker: currentSpeakerId.value } });
    const timeline = gsap.timeline();

    timeline
      .to(".panel-questions", {
        translateY: "-100dvh",
      })
      .to(".home-question-button", { translate: "-100% -100%" }, 0);
  }

  async function openPanelQuestions(id: number) {
    await router.replace({ query: { question: id } });
    const timeline = gsap.timeline();

    timeline
      .to(".panel-questions", {
        translateY: "0dvh",
      })
      .to(".home-question-button", { translate: "50% 35%" }, 0);

    trackQuestion(id);
  }

  function nextPanelQuestions() {
    const timeline = gsap.timeline();

    timeline
      .to(".panel-questions-element", {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          questionToDisplay.value = String((Number(questionToDisplay.value) % 5) + 1);
        },
      })
      .to(".panel-questions-element", { opacity: 1, duration: 0.5 });

    trackQuestion((Number(questionToDisplay.value) % 5) + 1);
  }
  function previousPanelQuestions() {
    const timeline = gsap.timeline();

    timeline
      .to(".panel-questions-element", {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          questionToDisplay.value = String((Number(questionToDisplay.value) - 1 + 5) % 5 || 5);
        },
      })
      .to(".panel-questions-element", { opacity: 1, duration: 0.5 });

    trackQuestion((Number(questionToDisplay.value) - 1 + 5) % 5 || 5);
  }

  watch(
    currentQuestionId,
    async () => {
      if (currentQuestionId.value) {
        await openPanelQuestions(Number(currentQuestionId.value));
      } else {
        await closePanelQuestions();
      }
    },
    { immediate: true },
  );

  return { previousPanelQuestions, closePanelQuestions, openPanelQuestions, nextPanelQuestions };
}
