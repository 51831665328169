import { IubendaEventName, useIubenda } from "@team-uep/vue-iubenda-op";
import { onBeforeMount, onUnmounted, ref } from "vue";

/**
 * Is Iubenda banner open or closed.
 * By default the value "isIubendaShown" is false.
 */
export default () => {
  const iubenda = useIubenda();
  const isIubendaShown = ref(false);

  const onShown = async () => {
    isIubendaShown.value = true;
    return Promise.resolve();
  };

  const onClosed = async () => {
    isIubendaShown.value = false;
    return Promise.resolve();
  };

  onBeforeMount(() => {
    iubenda.on(IubendaEventName.BANNER_SHOWN, onShown);
    iubenda.on(IubendaEventName.BANNER_CLOSED, onClosed);
  });

  onUnmounted(() => {
    iubenda.off(IubendaEventName.BANNER_SHOWN, onShown);
    iubenda.off(IubendaEventName.BANNER_CLOSED, onClosed);
  });

  return { isIubendaShown };
};
