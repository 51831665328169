/* eslint-disable ts/no-floating-promises */
import { createI18n } from "vue-i18n";

import { LANGUAGES_URL, TRANSLATIONS_URL } from "@/api";
import { fetchRetry } from "@/lib/fetch-retry";
import type { LanguagesResponse, TranslationsResponse } from "@/types/api.interfaces";

export const ALLOWED_LANGUAGES = ["fr", "en"];
export const FALLBACK_LANGUAGE = "fr";

// Detect the browser language, in the format of `fr-FR`.
const browserLang = navigator.language.split("-")[0];

// Set to null if the browser language is not in the ALLOWED_LANGUAGES list.
export const DEFAULT_BROWSER_LANGUAGE = ALLOWED_LANGUAGES.includes(browserLang)
  ? browserLang
  : null;

export function initializeI18n(callback: () => void) {
  // Split by `/` and remove empty strings.
  const segments = window.location.pathname.split("/").filter((s) => s);

  // Get the first segment of the URL, which is the language. If the language
  // is not in the ALLOWED_LANGUAGES list, use the FALLBACK_LANGUAGE.
  const lang = ALLOWED_LANGUAGES.includes(segments[0]) ? segments[0] : FALLBACK_LANGUAGE;

  const i18n = createI18n({
    locale: DEFAULT_BROWSER_LANGUAGE || FALLBACK_LANGUAGE,
    fallbackLocale: FALLBACK_LANGUAGE,
    legacy: false,
    warnHtmlMessage: false,
  });

  /**
   * Load all the translations from the API. This function is only called once,
   * during the initialiation of the app.
   *
   * - Load all existing languages.
   * - Load all translations for each languages.
   * - Put in-memory all translations for each language, so we can easily change
   *   the i18n locale.
   */
  async function loadTranslations() {
    try {
      // Get all languages available.
      const languagesResponse = await fetchRetry(LANGUAGES_URL);
      const languagesData = (await languagesResponse.json()) as LanguagesResponse;

      if (!languagesData.results) {
        throw new Error("Something went wrong, unable to load languages");
      }

      const languages = languagesData.results.map((l) => l.name.toLowerCase());

      // Load all translations for all languages.
      const translationResponse = await fetchRetry(TRANSLATIONS_URL);
      const translationData = (await translationResponse.json()) as TranslationsResponse;

      if (!translationData.results) {
        throw new Error("Something went wrong, unable to load all translations");
      }

      // Set the translations for each languages.
      languages.forEach((language) => {
        const messages: { [key: string]: string } = {};

        translationData
          .results!.filter((translation) => translation.lang.toLowerCase() === language)
          // eslint-disable-next-line antfu/consistent-chaining
          .forEach(({ key, value }) => {
            messages[key] = value;
          });

        i18n.global.setLocaleMessage(language, messages);
      });

      // Set the current locale based on the `?lang` URL param. If there is no
      // `?lang` param, use the FALLBACK_LANGUAGE locale.
      if (lang && i18n.global.availableLocales.includes(lang.toLowerCase())) {
        i18n.global.locale.value = lang;
      } else {
        i18n.global.locale.value = FALLBACK_LANGUAGE;
      }

      // Define the fallback locale (unknown translations will be in english).
      i18n.global.fallbackLocale.value = FALLBACK_LANGUAGE;
    } catch {
      console.error("Languages not loaded");
    }

    callback();
  }

  loadTranslations();

  return i18n;
}
